:root { 
--gray_50_01:#fafafa; 
--gray_50_02:#f4ffff; 
--gray_50_03:#fff7fc; 
--gray_50_04:#fbfbfb; 
--blue_A400:#2b87ff; 
--gray_50_05:#f8f8fa; 
--white_A700_3d:#ffffff3d; 
--red_800:#9d4841; 
--gray_800_04:#505050; 
--deep_orange_100_01:#fec4ad; 
--blue_gray_900_a2:#333333a2; 
--gray_800_03:#414141; 
--deep_orange_100_02:#fac4a8; 
--gray_800_02:#4c4c4c; 
--red_400:#e8505b; 
--gray_800_01:#424242; 
--blue_gray_900_6c:#2d2b2e6c; 
--teal_100:#abccd5; 
--black_900_01:#20000c; 
--cyan_400_af:#29c1c3af; 
--deep_orange_200_01:#ffbe9d; 
--teal_900:#133a50; 
--blue_gray_900_02:#313131; 
--blue_gray_900_01:#263238; 
--blue_gray_900:#323940; 
--gray_800_b2:#424242b2; 
--black_900_03:#020202; 
--black_900_02:#0a0a0d; 
--deep_orange_100:#edbda6; 
--black_900_04:#050505; 
--gray_400:#bababa; 
--indigo_900_6c:#1f2b6c6c; 
--blue_gray_100:#d4d7de; 
--gray_800:#383838; 
--blue_500:#159eec; 
--blue_gray_500:#4b7ba5; 
--deep_orange_600_26:#f3542726; 
--black_900_0c:#0000000c; 
--deep_purple_50:#efefff; 
--cyan_400_01:#29c5cf; 
--blue_gray_900_a2_01:#323940a2; 
--cyan_400_87:#29c1c387; 
--blue_100:#c0e7fa; 
--red_200_02:#e5a98e; 
--red_200_01:#e3b9a9; 
--black_900_19:#00000019; 
--cyan_600:#15a0c9; 
--black_900_af:#000000af; 
--blue_gray_100_01:#d9d9d9; 
--blue_gray_100_02:#d6d6db; 
--blue_gray_100_03:#cdd1e0; 
--red_300:#b78876; 
--cyan_400_2b:#29c1c32b; 
--gray_50:#fffbfb; 
--red_50:#fff0f2; 
--blue_gray_800_02:#34495e; 
--gray_500_01:#a9a9a9; 
--cyan_400_35:#29c1c335; 
--blue_gray_200_7a:#acabc27a; 
--pink_400:#d12590; 
--purple_50:#ffddfd; 
--red_300_02:#d58069; 
--blue_gray_200:#9cc0c9; 
--gray_500:#a6a6a6; 
--cyan_400_1e:#29c1c31e; 
--blue_gray_600:#5d5988; 
--gray_900:#212121; 
--red_300_01:#cc675f; 
--pink_A200_26:#f6208826; 
--teal_50:#dbf5f5; 
--blue_gray_800_01:#293854; 
--gray_100:#f7f7f7; 
--cyan_900:#1b5e7a; 
--cyan_500:#02ddcd; 
--white_A700_7f:#ffffff7f; 
--deep_orange_A100:#f7a281; 
--red_200:#e7a699; 
--blue_A200:#407bff; 
--black_900_3f:#0000003f; 
--teal_300:#409fae; 
--blue_gray_500_01:#6a769a; 
--gray_200_01:#ebebeb; 
--deep_orange_300:#ff725e; 
--blue_gray_700:#455a64; 
--gray_200_02:#f0f0f0; 
--red_A700:#ff0000; 
--gray_200_03:#e8e8e8; 
--gray_200_04:#ececec; 
--gray_600:#aa6550; 
--blue_gray_300:#94a3b8; 
--red_A200:#ff435e; 
--lime_900:#965545; 
--pink_A200_01:#f62088; 
--blue_gray_500_0c:#6a769a0c; 
--gray_200:#eeeeee; 
--orange_400:#ffac2f; 
--gray_100_03:#f2f2f2; 
--gray_100_02:#f4f4f4; 
--green_800_1c:#17ab001c; 
--gray_100_04:#f1f6ff; 
--gray_400_01:#c8c8c8; 
--gray_100_01:#f5f5f5; 
--blue_gray_100_a2:#d6d6dba2; 
--blue_gray_400_03:#6496ac; 
--blue_gray_500_19:#6a769a19; 
--blue_gray_400_02:#808194; 
--blue_gray_400_01:#5f97b3; 
--white_A700:#ffffff; 
--blue_gray_700_01:#3c6672; 
--teal_900_01:#123a5e; 
--cyan_400:#29c1c3; 
--blue_gray_400_87:#80899a87; 
--red_900:#a02724; 
--cyan_400_6c:#29c1c36c; 
--green_800:#17ab00; 
--red_400_01:#b55b52; 
--teal_200:#6eafcf; 
--pink_A200:#ff32b9; 
--black_900:#000000; 
--blue_gray_800:#313858; 
--deep_orange_200:#ffbf9d; 
--black_900_26:#00000026; 
--gray_900_02:#422129; 
--deep_orange_300_03:#eb996e; 
--gray_900_03:#09132e; 
--deep_orange_300_02:#ff6f61; 
--gray_700:#666666; 
--gray_900_04:#2a2529; 
--white_A700_6c:#fcfefe6c; 
--green_800_01:#00a50e; 
--gray_900_05:#292929; 
--gray_600_01:#838383; 
--blue_gray_400:#80899a; 
--gray_900_87:#09132e87; 
--blue_gray_900_05:#222b45; 
--deep_orange_300_01:#ff9a6c; 
--blue_gray_900_04:#2d2e2e; 
--indigo_50:#e2e8f0; 
--gray_900_01:#3d2716; 
--blue_gray_900_03:#333333; 
--light_blue_50:#d4f1ff; 
--gray_300_03:#e6e6e6; 
--gray_900_06:#202124; 
--gray_300:#e5e5e6; 
--gray_300_02:#e1d2e3; 
--gray_900_07:#212124; 
--gray_300_01:#e0e0e0; 
--gray_900_08:#181818; 
--white_A700_87:#ffffff87; 
--cyan_300:#4dbfd9; 
--orange_50:#ffe8db; 
--white_A700_01:#fcfefe; 
--indigo_900:#1f2b6c; 
--blue_100_01:#bed2f7; 
}